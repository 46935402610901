import React from 'react';
import {Link} from '../../components';

const BannerText = ({
  bannerProps,
}: {
  bannerProps: {text: string; link: string}[];
}) => {
  return (
    <div
      className={`pl-6 flex space-x-6 animate-loopScrollSlow group-hover:paused`}>
      {bannerProps.map((item, i) => {
        return (
          <>
            <Link className="hover:cursor-pointer" href={item.link} key={i}>
              <div className="whitespace-nowrap" key={i}>
                {item.text}
              </div>
            </Link>
            <div className="cursor-default">·</div>
          </>
        );
      })}
    </div>
  );
};

export const Banner = ({
  bannerItems,
}: {
  bannerItems: {text: string; link: string}[];
}) => {
  return (
    <div
      className={`py-2 bg-bluerice group flex overflow-hidden font-semibold text-background`}>
      <BannerText bannerProps={bannerItems} />
      <BannerText bannerProps={bannerItems} />
      <BannerText bannerProps={bannerItems} />
      <BannerText bannerProps={bannerItems} />
      <BannerText bannerProps={bannerItems} />
    </div>
  );
};
